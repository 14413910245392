import {
    LOGIN_ACTION, LOGIN_SUCCESS, LOGIN_FAIL,
    GET_VERIFICATION_CODE_ACTION, GET_VERIFICATION_CODE_SUCCESS, GET_VERIFICATION_CODE_FAIL,
    RESET_PASSWORD_ACTION, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS,
    SET_FIRST_PASSWORD_SUCCESS, SET_FIRST_PASSWORD_FAIL, SET_FIRST_PASSWORD_ACTION,
    LOGOUT_SUCCESS,
    GET_PROFILE_ACTION, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL,
} from '../../actions/authorization';

const initialState = {
    loginProcessing: false,
    isFirstLogin: false,
    username: null,
    sessionId: null,
    setFirstPasswordProcessing: false,
    postVerificationCodeProcessing: false,
    isVerificationCodeSent: false,
    resetPasswordProcessing: false,
    isResetPasswordSucceeded: false,
    loginError: null,
    verificationCodeError: null,
    error: null,
    profile: null,
    isProfileFetching: true,
    profileError: null,
};

const authorization = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ACTION:
            return { ...state, loginProcessing: true, loginError: null };
        case LOGIN_SUCCESS: {
            const { username, sessionId, firstLogin } = action.response;
            return {
                ...state,
                loginProcessing: false,
                isFirstLogin: firstLogin,
                username,
                sessionId,
            };
        }
        case LOGIN_FAIL:
            return { ...state, loginProcessing: false, loginError: action.error };
        case LOGOUT_SUCCESS:
            return { ...state, isFirstLogin: initialState.isFirstLogin };
        case SET_FIRST_PASSWORD_ACTION:
            return { ...state, setFirstPasswordProcessing: true, error: null };
        case SET_FIRST_PASSWORD_SUCCESS:
            return { ...state, setFirstPasswordProcessing: false, isFirstLogin: initialState.isFirstLogin };
        case SET_FIRST_PASSWORD_FAIL:
            return { ...state, setFirstPasswordProcessing: false, error: action.error };
        case GET_VERIFICATION_CODE_ACTION:
            return {
                ...state,
                postVerificationCodeProcessing: true,
                error: null,
                isVerificationCodeSent: false,
            };
        case GET_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                postVerificationCodeProcessing: false,
                isVerificationCodeSent: true,
                username: action.response.username,
            };
        case GET_VERIFICATION_CODE_FAIL:
            return { ...state, postVerificationCodeProcessing: false, verificationCodeError: action.error };
        case RESET_PASSWORD_ACTION:
            return {
                ...state,
                resetPasswordProcessing: true,
                error: null,
                isResetPasswordSucceeded: false,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordProcessing: false,
                isResetPasswordSucceeded: true,
                isVerificationCodeSent: false,
            };
        case RESET_PASSWORD_FAIL:
            return { ...state, resetPasswordProcessing: false, error: action.error };
        case GET_PROFILE_ACTION:
            return {
                ...state, profileError: null, profile: null, isProfileFetching: true,
            };
        case GET_PROFILE_SUCCESS:
            return {
                ...state, profileError: null, profile: action.response, isProfileFetching: false,
            };
        case GET_PROFILE_FAIL:
            return {
                ...state, profileError: action.error, profile: null, isProfileFetching: false,
            };
        default:
            return state;
    }
};

export const getLoginProcessing = state => state.authorization.loginProcessing;
export const getLoginError = state => state.authorization.loginError;
export const getIsFirstLogin = state => state.authorization.isFirstLogin;
export const getUserName = state => state.authorization.username;
export const getSessionId = state => state.authorization.sessionId;
export const getSetFirstPasswordProcessing = state => state.authorization.setFirstPasswordProcessing;
export const postVerificationCodeProcessing = state => state.authorization.postVerificationCodeProcessing;
export const getVerificationCodeError = state => state.authorization.verificationCodeError;
export const getIsVerificationCodeSent = state => state.authorization.isVerificationCodeSent;
export const getResetPasswordProcessing = state => state.authorization.resetPasswordProcessing;
export const getIsResetPasswordSucceeded = state => state.authorization.isResetPasswordSucceeded;
export const getError = state => state.authorization.error;
export const getProfileError = state => state.authorization.profileError;
export const getIsProfileFetching = state => state.authorization.isProfileFetching;
export const getProfile = state => state.authorization.profile;

export default authorization;
