import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tour from 'reactour';
import { Button } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import {
    getCombinedReports,
    getFetching,
} from '@src/store/reducers/reports';
import {
    fetchingReports,
    fetchReportsFailed,
    fetchReportsSucceeded,
} from '@src/store/actions/reports';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import Loader from '@src/components/Loader';
import ReportsFilter from '@src/components/ReportsFilter';
import { openOrdersTableColumns } from '@src/utils/constants/tablesColumns';
import { dxOpenOrdersTourSteps, defaultTourSettings } from '@src/utils/constants/tourSettings';
import { reportsTableType } from '@src/utils/templates/reportType';
import ReportsTable from '@src/components/ReportsTable';
import BillingEmailConfirmationModal from '@src/containers/billingEmailConfirmation/BillingEmailConfirmationModal';

const reportsType = reportsTableType.opened;

const filterFields = ['refno', 'text', 'orderno', 'type'];

const initialFilters = {
    refno: { matchType: 'contains', value: [] },
    text: { matchType: 'contains', value: [] },
    type: { matchType: 'in', value: [] },
    orderno: { matchType: 'contains', value: [] },
};

const filterFieldsMap = {
    type: 'smItem.itemCode',
};

const OpenOrdersContent = () => {
    const clearFormButtonRef = useRef();

    const isLoading = useSelector(getFetching);
    const combinedReports = useSelector(getCombinedReports);
    const { canSelectCustomer: isSctUser } = useSelector(getCurrentUser);
    const { number: currentCustomerId } = useSelector(getCurrentCustomer);

    const [filters, setFilters] = useState(initialFilters);
    const [additionalValueForFilter, setAdditionalValueForFilter] = useState({ name: '', value: '' });
    const [isTourOpen, setIsTourOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setFilters(initialFilters);
        clearFormButtonRef.current.click();
    }, [currentCustomerId]);

    const table = useMemo(() => (
        <ReportsTable
            tableColumns={openOrdersTableColumns}
            handleLoadingStart={() => dispatch(fetchingReports(reportsType))}
            handleLoadingSuccess={response => dispatch(fetchReportsSucceeded(response, reportsType))}
            handleLoadingError={error => dispatch(fetchReportsFailed(error, reportsType))}
            pushAdditionalValueForFilter={setAdditionalValueForFilter}
            reportsType={reportsType}
            filters={filters}
            combinedReports={combinedReports}
            isSctUser={isSctUser}
            shouldReloadData={currentCustomerId}
        />
    ),
    [filters, combinedReports, currentCustomerId]);

    return (
        <>
            <div className="contentHeader">
                <h1>
                    <span>Open Orders</span>
                    <Button shape="circle" icon={<InfoOutlined />} onClick={() => setIsTourOpen(true)} />
                </h1>
            </div>
            <Loader show={isLoading}>
                <ReportsFilter
                    initialValues={initialFilters}
                    isDisabled={isLoading}
                    onSubmit={data => setFilters(
                        Object
                            .keys(data)
                            .reduce((acc, key) => ({
                                ...acc,
                                [filterFieldsMap[key] || key]: data[key],
                            }), {}),
                    )}
                    filterFields={filterFields}
                    additionalValueForFilter={additionalValueForFilter}
                    clearFormButtonRef={clearFormButtonRef}
                />
                {table}
                <Tour
                    {...defaultTourSettings}
                    steps={dxOpenOrdersTourSteps}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                />
            </Loader>

            <BillingEmailConfirmationModal />
        </>
    );
};

export default OpenOrdersContent;
