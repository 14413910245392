import React from 'react';
import platform from 'platform';
import { Collapse, Steps, Typography } from 'antd';

const appDomain = process.env.APP_DOMAIN;

const messageMap = {
    'Chrome': (
        <>
            Please try to delete cookies here (
            <Typography.Text copyable>
                {`chrome://settings/content/all?searchSubpage=${appDomain}`}
            </Typography.Text>
            )
        </>
    ),
    'Opera': (
        <>
            Please try to delete cookies here (
            <Typography.Text copyable>
                {`opera://settings/content/all?searchSubpage=${appDomain}`}
            </Typography.Text>
            )
        </>
    ),
    'Microsoft Edge': (
        <>
            Please try to delete cookies here (
            <Typography.Text copyable>
                {`edge://settings/content/cookies/siteData?searchSubpage=${appDomain}`}
            </Typography.Text>
            )
        </>
    ),
    'Firefox': (
        <>
            Click the "Lock" icon in the URL bar, then click the "Clear cookies and site data..." button
            <div>or</div>
            <Collapse ghost>
                <Collapse.Panel header="Try to delete cookies manually">
                    <Steps
                        progressDot
                        direction="vertical"
                        current={4}
                        items={[
                            {
                                title: (
                                    <>
                                        Go to Firefox settings (
                                        <Typography.Text copyable>
                                            about:preferences#privacy
                                        </Typography.Text>
                                        )
                                    </>
                                ),
                                description: 'Privacy & Security > Cookies and Site Data',
                            },
                            {
                                title: "Click 'Manage Data...' button",
                            },
                            {
                                title: 'Find & Delete Cookie',
                                description: (
                                    <>
                                        {'Search for '}
                                        <Typography.Text copyable>
                                            statecapital.net
                                        </Typography.Text>
                                        {' cookie, select and remove it'}
                                    </>
                                ),
                            },
                        ]}
                    />
                </Collapse.Panel>
            </Collapse>
        </>
    ),
    'Safari': (
        <>
            <Typography.Title level={4}>
                Please try to delete cookies manually
            </Typography.Title>
            <Steps
                progressDot
                direction="vertical"
                current={4}
                items={[
                    {
                        title: 'Go to Safari settings',
                        description: 'Safari > Settings',
                    },
                    {
                        title: "Select 'Privacy' Tab > Click 'Manage Website Data...' button",
                    },
                    {
                        title: 'Find & Delete Cookie',
                        description: (
                            <>
                                {'Search for '}
                                <Typography.Text copyable>
                                    statecapital.net
                                </Typography.Text>
                                {' cookie, select and remove it'}
                            </>
                        ),
                    },
                ]}
            />
        </>
    ),
};

const ClearCookieMessage = () => (
    <>
        {messageMap[platform.name] || `Please manually delete your cookies for ${appDomain} subdomain`}
    </>
);

export default ClearCookieMessage;
