import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal, Col, Row, Button,
} from 'antd';
import { Form, Field } from 'react-final-form';
import {
    getConfirming,
    getConfirmationBillingInfo,
} from '@src/store/reducers/customers';
import {
    fetchCustomersBillingEmail,
    confirmCustomerBillingEmail,
} from '@src/store/actions/customers';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import {
    composeValidators,
    maxLengthForTags,
    multiEmailTags,
    requiredAtLeastOneInArray,
} from '@src/utils/helpers/validationRules';
import { emailsJoiner, emailsSeparators } from '@src/utils/constants/formConstants';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';

const BillingEmailConfirmationModal = () => {
    const isConfirming = useSelector(getConfirming);
    const confirmationBillingInfo = useSelector(getConfirmationBillingInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCustomersBillingEmail());
    }, []);

    return (
        <>
            {
                confirmationBillingInfo
                && (
                    <Modal
                        closable={false}
                        visible={
                            confirmationBillingInfo
                            && !confirmationBillingInfo.canceled
                            && !confirmationBillingInfo.confirmed
                            && !confirmationBillingInfo.skipToProcess
                        }
                        title="Billing email address verification"
                        footer={null}
                    >
                        <p>Please confirm your billing email</p>
                        <Form
                            onSubmit={ev => {
                                dispatch(confirmCustomerBillingEmail(
                                    ev.email,
                                    true,
                                    false,
                                    confirmationBillingInfo,
                                ));
                            }}
                            initialValues={{
                                'email': confirmationBillingInfo.email
                                    ? confirmationBillingInfo.email
                                        .split(multiEmailSplitter)
                                        .filter(item => !!item.length)
                                    : [],
                            }}
                        >
                            {
                                ({
                                    handleSubmit,
                                    form,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col span={24}>
                                                <Field
                                                    name="email"
                                                    labelText="Billing Email"
                                                    component={InputGroupAntTagBoxAdapter}
                                                    autoFocus
                                                    acceptCustomValue
                                                    tokenSeparators={emailsSeparators}
                                                    validate={composeValidators(
                                                        requiredAtLeastOneInArray,
                                                        multiEmailTags,
                                                        maxLengthForTags(255, emailsJoiner),
                                                    )}
                                                    isRequired
                                                    formSubmit={form.submit}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <Button
                                                    className="btn btn-primary"
                                                    htmlType="submit"
                                                    disabled={isConfirming}
                                                    type="primary"
                                                >
                                                    Confirm
                                                </Button>
                                                <Button
                                                    className="cancel btn btn-default left-buffer-xs"
                                                    htmlType="button"
                                                    disabled={isConfirming}
                                                    onClick={() => {
                                                        dispatch(confirmCustomerBillingEmail(
                                                            confirmationBillingInfo.email,
                                                            false,
                                                            true,
                                                            confirmationBillingInfo,
                                                        ));
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                )
                            }
                        </Form>
                    </Modal>
                )
            }
        </>
    );
};

export default BillingEmailConfirmationModal;
