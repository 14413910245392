import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LogoImage from '@src/images/statecapital-logo.png';
import { STATIC_SITE_URL } from '@src/utils/constants/routerConstants';
import UnauthorizedMenu from '@src/components/UnauthorizedMenu';

const UnauthorizedNavigation = () => {
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);

    return (
        <nav role="navigation" className="navbar bottom-buffer-no">
            <div className="container container-header">
                <div className="site-branding" id="logo">
                    <a href={STATIC_SITE_URL} id="indexPageLink">
                        <img
                            border="0"
                            alt="State Capital Title &amp; Abstract Co."
                            src={LogoImage}
                        />
                    </a>
                </div>
                <div
                    id="mainNavbar"
                    className="main-nav"
                >
                    <UnauthorizedMenu />
                    <Button
                        type="text"
                        className="main-nav-mobile-toggle-btn"
                        icon={isSideMenuVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                        onClick={() => setIsSideMenuVisible(prevState => !prevState)}
                    />
                    <Drawer
                        width={250}
                        placement="right"
                        closable={false}
                        onClose={() => setIsSideMenuVisible(false)}
                        open={isSideMenuVisible}
                    >
                        <UnauthorizedMenu mode="inline" />
                    </Drawer>
                </div>
            </div>
        </nav>
    );
};

export default UnauthorizedNavigation;
