import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import {
    useDispatch, useSelector,
} from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Modal,
} from 'antd';
import { Carousel } from 'react-responsive-carousel';
import '@src/style/propertySearchOrder.less';
import { downloadConfirmation, postOrder } from '@src/store/actions/orders';
import {
    getContactInformationConfirmation,
    getDeliveryInformationConfirmation,
    getMainInformationConfirmation,
    getOrderInformationConfirmation,
} from '@src/utils/helpers/orders';
import OrderConfirmation from '@src/components/orders/OrderConfirmation';
import { getError, getPostedOrder, getPosting } from '@src/store/reducers/orders';
import { getMunicipalities, getCounties, getStates } from '@src/store/reducers/states';
import {
    getMunicipalities as fetchMunicipalities,
    getAllStates as fetchAllStates,
} from '@src/store/actions/states';
import {
    getAccountSettings as fetchAccountSettings,
    getAccountDeliverySettings as fetchAccountDeliverySettings,
} from '@src/store/actions/accountSettings';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import { getCurrentCustomer, getCurrentCustomerFetching } from '@src/store/reducers/customers';
import {
    getProperty,
    getFetchingProperty,
    getTaxContinuationReference,
    getTaxContinuationCriteria,
} from '@src/store/reducers/reports';
import {
    fetchProperty,
    resetProperty,
    resetTaxContinuationProperty,
    fetchTaxContinuationProperty,
} from '@src/store/actions/reports';
import {
    getInitialServiceData,
    getMainInformation,
    prepareDataForRequest,
    orderServiceModes,
    initialSearchFormData,
    getCountyIdByName,
    getMunicipalityIdByName,
} from '@src/containers/orderServicePropertySearch/helper';
import OrderPreview from '@src/components/orders/OrderPreview';
import { getDeliverySettings, getPropertySearchSettings, getReportSettings } from '@src/store/reducers/accountSettings';
import SelectPropertyForm from '@src/containers/orderServicePropertySearch/SelectPropertyForm';
import SelectPropertySearchResults from '@src/containers/orderServicePropertySearch/SelectPropertySearchResults';
import SelectServicesForm from '@src/containers/orderServicePropertySearch/SelectServicesForm';
import StepsNavigation from '@src/containers/orderServicePropertySearch/StepsNavigation';
import {
    ORDER_SERVICE_PROPERTY_SEARCH,
    ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION,
} from '@src/utils/constants/routerConstants';

const OrderServicePropertySearchContent = () => {
    const {
        EDIT,
        SELECT_RESULTS,
        SELECT_FORM,
        PREVIEW,
        CONFIRMATION,
    } = orderServiceModes;

    const { id: continuationOrderId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const isPostingOrder = useSelector(getPosting);
    const postedOrder = useSelector(getPostedOrder);
    const postedOrderError = useSelector(getError);
    const deliveryOptions = useSelector(getDeliverySettings);
    const propertySearchSettings = useSelector(getPropertySearchSettings);
    const reportSettings = useSelector(getReportSettings);
    const municipalities = useSelector(getMunicipalities);
    const states = useSelector(getStates);
    const counties = useSelector(getCounties);
    const property = useSelector(getProperty);
    const taxContinuationReference = useSelector(getTaxContinuationReference);
    const taxContinuationCriteria = useSelector(getTaxContinuationCriteria);
    const isFetchingProperty = useSelector(getFetchingProperty);
    const currentUser = useSelector(getCurrentUser);
    const currentCustomer = useSelector(getCurrentCustomer);
    const isCurrentCustomerFetching = useSelector(getCurrentCustomerFetching);

    const [mode, setMode] = useState(continuationOrderId ? EDIT : SELECT_FORM);
    const [searchFormData, setSearchFormData] = useState(initialSearchFormData);
    const [selectedProperty, setSelectedProperty] = useState([]);
    const [deliveryData, setDeliveryData] = useState(deliveryOptions);
    const [serviceData, setServiceData] = useState(getInitialServiceData(continuationOrderId));
    const [selectPropertyForm, setSelectPropertyForm] = useState();
    const [servicesForm, setServicesForm] = useState();
    const [isContinuation, setIsContinuation] = useState(!!continuationOrderId);

    const dispatch = useDispatch();

    const propertySelectGridRef = useRef();
    const carouselRef = useRef();
    const carouselRefNoRef = useRef();
    const pageHeaderRef = useRef();
    const nextWithoutSearchButtonRef = useRef();
    const nextWithSelectedPropertyButtonRef = useRef();
    const validateSearchOrderFormButtonRef = useRef();
    const previewOrderButtonRef = useRef();

    useEffect(() => {
        dispatch(fetchMunicipalities());
        dispatch(fetchAllStates());
        dispatch(fetchAccountSettings());
        dispatch(fetchAccountDeliverySettings());
        if (continuationOrderId) {
            dispatch(fetchTaxContinuationProperty(continuationOrderId));
        }
        if (isCurrentCustomerFetching && currentCustomer.number) {
            goToSearchStep(false);
        }
        setServiceData(getInitialServiceData(continuationOrderId));
        setSearchFormData(initialSearchFormData);
        setSelectedProperty([]);
    }, [isCurrentCustomerFetching]);

    useEffect(() => () => {
        if (isContinuation) {
            dispatch(resetTaxContinuationProperty());
        } else {
            dispatch(resetProperty());
        }
    }, []);

    useEffect(() => {
        if (location.pathname !== ORDER_SERVICE_PROPERTY_SEARCH) {
            return;
        }

        if (isContinuation) {
            dispatch(resetTaxContinuationProperty());
            setIsContinuation(false);
        }

        dispatch(resetProperty());
        setMode(SELECT_FORM);
        setSearchFormData(initialSearchFormData);
        setSelectedProperty([]);
        setServiceData(getInitialServiceData());
    }, [location]);

    useEffect(() => {
        setServiceData(prevState => ({ ...prevState, reference: taxContinuationReference }));
        setSelectedProperty(isContinuation ? (property || []) : []);
        if (taxContinuationCriteria) {
            const { county: countyName, municipality: municipalityName } = taxContinuationCriteria;
            const county = getCountyIdByName(counties, countyName);
            const municipality = getMunicipalityIdByName(municipalities, municipalityName);
            setSearchFormData(prev => ({
                ...prev,
                ...taxContinuationCriteria,
                county: county ? county.toString() : '',
                municipality: municipality ? municipality.toString() : '',
            }));
            if (!municipality) {
                setMode(SELECT_FORM);
                setTimeout(() => document.getElementById('validate-search-for-property-form-button').click(), 1);
            }
        }
    }, [taxContinuationReference, counties, municipalities, property, taxContinuationCriteria]);

    useEffect(() => {
        setDeliveryData(deliveryOptions);
    }, [deliveryOptions]);

    useEffect(() => {
        if (pageHeaderRef.current && pageHeaderRef.current.getBoundingClientRect().top < 0) {
            pageHeaderRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mode]);

    const unselectProperty = id => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Do you want remove a property from search?',
            okText: 'Remove',
            cancelText: 'Cancel',
            onOk: () => setSelectedProperty(prevState => prevState.filter(({ recordKey }) => recordKey !== id)),
        });
    };

    const cancelContinuation = useCallback(() => {
        setIsContinuation(false);
        servicesForm.change('selectedServicesTypes.taxContinuationService', false);
    }, [servicesForm]);

    const goToSearchStep = useCallback((isFilterNeeded = true) => {
        if (isContinuation) {
            dispatch(resetTaxContinuationProperty());
            navigate(`${ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION}${isFilterNeeded ? location.search : ''}`);
        } else {
            setMode(
                selectedProperty.length
                    ? SELECT_RESULTS
                    : SELECT_FORM,
            );
        }
    }, [location, isContinuation, selectedProperty]);

    const goToNextOrder = () => {
        if (isContinuation) {
            dispatch(resetTaxContinuationProperty());
            navigate(`${ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION}${location.search}`);
        } else {
            dispatch(resetProperty());
            setMode(SELECT_FORM);
            setSearchFormData(initialSearchFormData);
            setSelectedProperty([]);
            setServiceData(getInitialServiceData());
        }
    };

    return (
        <Row>
            <Col
                md={{ span: 16, offset: 4 }}
                sm={{ span: 20, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
            >
                {
                    mode !== CONFIRMATION
                    && (
                        <>
                            <div className="contentHeader text-center">
                                <h1 ref={pageHeaderRef}>Property Search Order Form</h1>
                            </div>
                            <StepsNavigation
                                mode={mode}
                                setMode={setMode}
                                selectedPropertyDataGrid={propertySelectGridRef.current}
                                selectPropertyForm={selectPropertyForm}
                                servicesForm={servicesForm}
                                orderServiceModes={orderServiceModes}
                                nextWithoutSearchButton={nextWithoutSearchButtonRef.current}
                                nextWithSelectedPropertyButton={nextWithSelectedPropertyButtonRef.current}
                                validateSearchOrderFormButton={validateSearchOrderFormButtonRef.current}
                                previewOrderButton={previewOrderButtonRef.current}
                                goToSearchStep={goToSearchStep}
                            />
                            <Carousel
                                className="one-field-carousel"
                                ref={carouselRefNoRef}
                                autoPlay={false}
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}
                                showIndicators={false}
                                selectedItem={mode === PREVIEW ? 1 : 0}
                            >
                                <div>
                                    <div className="reference-field-portal" />
                                </div>
                                <div>
                                    <div className="formBlock roll-in-block-small">
                                        <div className="section">
                                            <div>
                                                <dl className="dl-horizontal">
                                                    <dt>Reference Number</dt>
                                                    <dd>{serviceData.reference}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                            <Carousel
                                ref={carouselRef}
                                autoPlay={false}
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}
                                showIndicators={false}
                                swipeable={false}
                                selectedItem={mode}
                            >
                                <div>
                                    <SelectPropertyForm
                                        goToSearchStep={() => setMode(SELECT_RESULTS)}
                                        goToEditStep={() => setMode(EDIT)}
                                        municipalities={municipalities}
                                        counties={counties}
                                        formData={searchFormData}
                                        setSearchParams={setSearchFormData}
                                        searchPropertyHandler={params => dispatch(fetchProperty(params))}
                                        isSearching={isFetchingProperty}
                                        searchResults={property}
                                        setForm={setSelectPropertyForm}
                                        isContinuation={isContinuation}
                                        nextWithoutSearchButtonRef={nextWithoutSearchButtonRef}
                                        validateSearchOrderFormButtonRef={validateSearchOrderFormButtonRef}
                                    />
                                </div>
                                <div>
                                    <SelectPropertySearchResults
                                        goToSearchStep={() => {
                                            dispatch(resetProperty());
                                            setMode(SELECT_FORM);
                                            cancelContinuation();
                                        }}
                                        goToEditStep={() => setMode(EDIT)}
                                        setSelectedProperty={setSelectedProperty}
                                        selectedProperty={selectedProperty}
                                        property={property}
                                        ref={propertySelectGridRef}
                                        nextWithSelectedPropertyButtonRef={nextWithSelectedPropertyButtonRef}
                                        isContinuation={isContinuation}
                                    />
                                </div>
                                <div>
                                    <SelectServicesForm
                                        goToSearchStep={goToSearchStep}
                                        goToPreviewStep={() => setMode(PREVIEW)}
                                        selectedProperty={selectedProperty}
                                        searchPropertyFormData={searchFormData}
                                        municipalities={municipalities}
                                        counties={counties}
                                        states={states}
                                        propertySearchSettings={propertySearchSettings}
                                        formData={serviceData}
                                        setData={setServiceData}
                                        isReferenceRequired={reportSettings.referenceRequired}
                                        setForm={setServicesForm}
                                        customerName={(currentCustomer.name) || currentUser.customerName}
                                        isPropertyLoading={isFetchingProperty}
                                        isContinuation={isContinuation}
                                        previewOrderButtonRef={previewOrderButtonRef}
                                        isVisible={mode === EDIT}
                                    />
                                </div>
                                <div>
                                    <OrderPreview
                                        setNextStep={() => setMode(CONFIRMATION)}
                                        setPrevStep={() => setMode(EDIT)}
                                        onSubmitOrder={data => {
                                            dispatch(postOrder(
                                                selectedProperty.length
                                                    ? 'propertySearch/propertyList'
                                                    : 'propertySearch/propertyCriteria',
                                                prepareDataForRequest(
                                                    serviceData,
                                                    data,
                                                    selectedProperty,
                                                    searchFormData,
                                                    municipalities,
                                                    counties,
                                                    isContinuation,
                                                ),
                                            ));
                                            setDeliveryData(deliveryOptions);
                                        }}
                                        setDeliveryData={setDeliveryData}
                                        deliveryInformation={deliveryData}
                                        mainInformation={getMainInformation(
                                            serviceData,
                                            selectedProperty,
                                            searchFormData,
                                            municipalities,
                                            counties,
                                            unselectProperty,
                                        )}
                                        isAccutitleVisible={false}
                                        isRushVisible={false}
                                    />
                                </div>
                            </Carousel>
                        </>
                    )
                }
                {
                    mode === CONFIRMATION
                    && (
                        <OrderConfirmation
                            setNextStep={goToNextOrder}
                            downLoadConfirmation={orderId => dispatch(downloadConfirmation(orderId))}
                            isPostingOrder={isPostingOrder}
                            postedOrderError={postedOrderError}
                            order={postedOrder}
                            orderInformation={getOrderInformationConfirmation(postedOrder)}
                            contactInformation={getContactInformationConfirmation(postedOrder)}
                            deliveryInformation={getDeliveryInformationConfirmation(postedOrder)}
                            ordersList={getMainInformationConfirmation(postedOrder, ['type', 'reportName'])}
                        />
                    )
                }
            </Col>
        </Row>
    );
};

export default OrderServicePropertySearchContent;
