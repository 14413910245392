import { addErrorAlert } from '@src/store/actions/alerts';
import {
    fetchCustomers as fetchCustomersApi,
    fetchCurrentCustomer as fetchCurrentCustomerApi,
    selectCustomer as selectCustomerApi,
    selectCustomerReset as selectCustomerResetApi,
    checkDefaultCustomer as checkDefaultCustomerApi,
    fetchAffiliatedCustomers as fetchAffiliatedCustomersApi,
    selectAffiliatedCustomer as selectAffiliatedCustomerApi,
    fetchCustomerBillingEmail as fetchCustomerBillingEmailApi,
    putCustomerBillingEmail as putCustomerBillingEmailApi,
} from '@src/utils/apiServices/customersRequests';
import {
    checkDefaultCustomerAction, checkDefaultCustomerFailed,
    checkDefaultCustomerSucceeded,
} from '@src/store/actions/customers';
import { fetchReports } from '@src/store/actions/reports';
import { reportsTableType } from '@src/utils/templates/reportType';
import {
    fetchingCustomers,
    fetchCustomersSucceeded,
    fetchCustomersFailed,
    fetchingCurrentCustomer,
    fetchCurrentCustomerSucceeded,
    fetchCurrentCustomerFailed,
    selectingCustomer,
    selectCustomerSucceeded,
    selectCustomerFailed,
    fetchAffiliatedCustomersAction,
    fetchAffiliatedCustomersSucceeded,
    fetchAffiliatedCustomersFailed,
    selectAffiliatedCustomerAction,
    selectAffiliatedCustomerSucceeded,
    selectAffiliatedCustomerFailed,
    fetchCustomerBillingEmailSucceeded,
    fetchCustomerBillingEmailFailed,
    confirmingCustomerBillingEmail,
    confirmCustomerBillingEmailSucceeded,
    confirmCustomerBillingEmailFailed,
} from './customersControl';

export const fetchCustomers = (fields, filters) => dispatch => {
    dispatch(fetchingCustomers());

    return fetchCustomersApi(fields, filters).then(response => {
        dispatch(fetchCustomersSucceeded(response));
    }).catch(error => {
        dispatch(fetchCustomersFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching customers.', true));
    });
};

export const fetchCurrentCustomer = () => dispatch => {
    dispatch(fetchingCurrentCustomer());

    return fetchCurrentCustomerApi().then(response => {
        dispatch(fetchCurrentCustomerSucceeded(response));
    }).catch(error => {
        dispatch(fetchCurrentCustomerFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching current customer.', true));
    });
};

export const fetchCustomersBillingEmail = () => dispatch => fetchCustomerBillingEmailApi().then(response => {
    dispatch(fetchCustomerBillingEmailSucceeded(response));
}).catch(error => {
    dispatch(fetchCustomerBillingEmailFailed(error));
    dispatch(addErrorAlert('Something went wrong while fetching customer billing email conformation.', true));
});

export const confirmCustomerBillingEmail = (emails, isConfirmed, isCanceled, billingEmailInfo) => dispatch => {
    dispatch(confirmingCustomerBillingEmail());

    return putCustomerBillingEmailApi(
        emails,
        isConfirmed,
        isCanceled,
        billingEmailInfo,
    ).then(response => {
        dispatch(confirmCustomerBillingEmailSucceeded(response));
    }).catch(error => {
        dispatch(confirmCustomerBillingEmailFailed(error));
        dispatch(addErrorAlert('Something went wrong while customer billing email conformation.', true));
    });
};

export const selectCustomer = (id, cb = () => {}) => dispatch => {
    dispatch(selectingCustomer());

    return selectCustomerApi(id).then(() => {
        Promise.all([
            dispatch(fetchCurrentCustomer()),
            dispatch(fetchAffiliatedCustomers()),
            dispatch(selectCustomerSucceeded()),
        ]).then(() => {
            cb();
        });
    }).catch(error => {
        dispatch(selectCustomerFailed(error));
        dispatch(addErrorAlert('Something went wrong while selecting customer.', true));
    });
};

export const resetCustomer = (cb = () => {}) => dispatch => {
    dispatch(selectingCustomer());

    return selectCustomerResetApi().then(() => {
        dispatch(fetchReports(reportsTableType.new, [], {}, true));
        dispatch(fetchCurrentCustomer());
        dispatch(fetchAffiliatedCustomers());
        dispatch(selectCustomerSucceeded());
        cb();
    }).catch(error => {
        dispatch(selectCustomerFailed(error));
        dispatch(addErrorAlert('Something went wrong while resetting customer.', true));
    });
};

export const checkDefaultCustomer = () => dispatch => {
    dispatch(checkDefaultCustomerAction());
    return checkDefaultCustomerApi().then(response => {
        dispatch(checkDefaultCustomerSucceeded(response));
    }).catch(error => {
        dispatch(checkDefaultCustomerFailed(error));
        dispatch(addErrorAlert('Something went wrong during select customer settings. Please, try again later.'));
    });
};

export const fetchAffiliatedCustomers = () => dispatch => {
    dispatch(fetchAffiliatedCustomersAction());
    return fetchAffiliatedCustomersApi().then(response => {
        dispatch(fetchAffiliatedCustomersSucceeded(response));
    }).catch(error => {
        dispatch(fetchAffiliatedCustomersFailed(error));
        dispatch(addErrorAlert('Something while fetching affiliated customers. Please, try again later.'));
    });
};

export const selectAffiliatedCustomer = id => dispatch => {
    dispatch(selectAffiliatedCustomerAction());
    return selectAffiliatedCustomerApi(id).then(() => {
        dispatch(selectAffiliatedCustomerSucceeded(id));
        dispatch(fetchCurrentCustomer());
    }).catch(error => {
        dispatch(selectAffiliatedCustomerFailed(error));
        dispatch(addErrorAlert('Something while selecting affiliated customer. Please, try again later.'));
    });
};
